<template>
  <b-row>
      <b-card
        border-variant="danger"
        :header="'Lote '+ chart.lote"
        header-bg-variant="danger"
        header-text-variant="white"
        align="center" class="ml-2 mb-2"
        v-for="chart in charts" :key="chart.lote">
        <b-card-text>
          <apexchart width="350px" :options="chartOptions" :series="[chart.entregadas_per,chart.sin_entregar_per]"></apexchart>
          <div align="left">
            Total: {{chart.total}}<br>
            Entregadas: {{chart.entregadas}}<br>
            Por Entregar: {{chart.sin_entregar}}<br>
            Sin Registro Consular: {{chart.sinRC}}
          </div>
        </b-card-text>
      </b-card>
  </b-row>
</template>

<script>
import apexchart from 'vue-apexcharts'

export default {
  name: 'stats_info',
  components:{
    apexchart
  },
  data(){
    return{
      charts: null,
      chartOptions:{ 
        chart: { type: 'pie', width: 200 },
        labels: ['Entregados','Por Entregar'],
        colors: ['#5beb44', '#629bee'],
        // colors: ['#5beb44', '#fefe03'],
        responsive: [{
          breakpoint: 400,
          options: {
            chart: {width: 200},
            legend: { position: 'bottom'}
          }
        }]
      }
    }
  },
  async mounted(){
    this.charts = await fetch('/api/get_stats_data').then(res => res.json());
    console.log(this.charts)
  }
  }
</script>