<template>
  <div>
    <b-tabs content-class="mt-3">
    <b-tab title="Status Information">
      <stats_info/>
    </b-tab>
    <b-tab title="Prórroga System" active>
      <box Title="Prórrogas Information">
        <b-pagination-nav :number-of-pages="totalPages" base-url="#" first-text="First" prev-text="Prev" next-text="Next" last-text="Last" @page-click="pageClick"></b-pagination-nav>
        <b-button v-b-modal.search size="small" class="mb-2" variant="primary">Filter By</b-button>
        <b-modal id="search" title="filter">
          <b-row cols="1">
            <b-form>
              <label class="" for="lote">Lote:</label>
                <b-form-input v-model="search.lote" id="lote" type="number" size="sm" class="mr-1 mb-1" placeholder="Lote"></b-form-input>

              <label class="" for="serial">Serial:</label>
                <b-form-input v-model="search.serial" id="serial" size="sm" class="mr-1 mb-1" placeholder="Serial"></b-form-input>

              <label class="" for="searchFsolicitud">Fecha de Solicitud:</label>
                <b-form-input v-model="search.f_solicitud" id="searchFsolicitud" size="sm" type="date" class="mr-1 mb-1" placeholder="F. Solicitud"></b-form-input>

              <label class="" for="cedula">Cédula</label>
                <b-form-input v-model="search.cedula" id="cedula" size="sm" class="mr-1 mb-1" placeholder="# Cédula"></b-form-input>

              <label class="" for="sname">Nombre/Apellido</label>
                <b-form-input v-model="search.fullName" id="sname" size="sm" class="mr-1 mb-1" placeholder="Name/Last Name"></b-form-input>

              <label class="" for="RC">RC</label>
                <b-form-input v-model="search.RC" id="RC" size="sm" class="mr-1 mb-1" placeholder="RC"></b-form-input>

              <label class="" for="searchFestampado">Fecha de Estampado</label>
                <b-form-input v-model="search.f_estampado" size="sm" id="searchFestampado" type="date" class="mr-1 mb-1" placeholder="F. Estampado"></b-form-input>

              <label class="" for="status">Estatus</label>
                <b-form-input v-model="search.status" size="sm" id="status" type="number" class="mr-1 mb-1" placeholder="Status"></b-form-input>

              <label class="" for="email">Email</label>
                <b-form-input v-model="search.email" size="sm" id="email" class="mr-1 mb-1" placeholder="Email"></b-form-input>

              <label class="" for="phone">Teléfono</label>
                <b-form-input v-model="search.phone" size="sm" id="phone" class="mr-1 mb-1" placeholder="Phone"></b-form-input>
            </b-form>
          </b-row>
          <template #modal-footer="{ ok, close }">
            <b-button size="sm" variant="primary" @click="ok(searchquery())">Buscar</b-button>
            <b-button size="sm" variant="danger" @click="close()">Cerrar</b-button>
          </template>
        </b-modal>
          <b-table hover striped header-variant="dark" :items="items" :fields="fields" :tbody-tr-class="rowColor" small responsive>
            <template #cell(email)="data">
              <b-link :href="'mailto:'+data.value">{{data.value}}</b-link>
            </template>
            <template #cell(tracking)="data">
              <b-link target="_blank" :href="'https://auspost.com.au/mypost/track/#/details/'+data.value">
              <b-img src="../assets/Australia_Post_logo.png" width="35px"></b-img></b-link>
            </template>
            <template #cell(phone)="data">
              <b-link :href="'https://api.whatsapp.com/send?phone='+data.value" target="_blank"><b-img src="../assets/whatsapp-icon.png" width="35px"></b-img></b-link>{{data.value}}
            </template>
            <template #cell(update)="data">
              <b-button @click="showEdit(data.item)" size="sm" variant="primary">Actualizar</b-button>
              <b-modal :id="data.item.serial" :title="'Update for '+ data.item.serial" header-bg-variant="info" header-text-variant="light">
                <b-form-group id="phone" label="Phone #:" label-for="phone">
                  <b-form-input id="phone" v-model="form.phone"></b-form-input>
                </b-form-group>
                <b-form-group id="rc" label="Registro Consular:" label-for="rc">
                  <b-form-select id="rc" v-model="form.RC" :options="rcOpts"></b-form-select>
                </b-form-group>
                <b-form-group id="status" label="Procesado:" label-for="status">
                  <b-form-select id="status" v-model="form.status" :options="statusOpts"></b-form-select>
                </b-form-group>
                <div v-if="form.status">
                  <b-form-group id="tracking" label="Tracking #:" label-for="tracking" required>
                  <b-form-input id="tracking" v-model="form.tracking"></b-form-input>
                </b-form-group>
                <b-form-group id="f_estampado" label="Procesada el:" label-for="f_estampado">
                  <b-form-datepicker id="f_estampado" locale="en-AU" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }" v-model="form.f_estampado"></b-form-datepicker>
                </b-form-group>
                </div>
                <template #modal-footer="{ ok, close }">
                  <b-button size="sm" variant="success" @click="ok(updatereg(data.item.serial))">Actualizar</b-button>
                  <b-button size="sm" variant="danger" @click="close()">Cerrar</b-button>
                </template>
              </b-modal>
            </template>
            <template #cell(SendEmail)="data">
              <b-button @click="SendEmail(data)" size="sm" variant="primary">Enviar</b-button>
            </template>
          </b-table>
        </box>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import box from '../components/parts/box.vue';
import stats_info from '../components/stats_info.vue';

export default {
  name: 'prorrogas',
  components: {
    box,
    stats_info
  },
  data(){
    return {
      form: {
        RC: null,
        status: null,
        tracking: null,
        f_estampado: null,
        phone: null
      },
      search: {
        lote: undefined,
        fullName: undefined,
        cedula: undefined,
        phone: undefined,
        f_estampado: undefined,
        f_solicitud: undefined,
        serial: undefined,
        status: undefined,
        RC: undefined
      },
      filter_name: null,
      items: [],
      fields: [{ key: 'id', label: 'ID'}, { key: 'lote', label: 'Lote', sortable: true},'serial', { key: 'f_solicitud', label: 'Fecha de Solicitud', formatter: value => {return this.dateFormat(value)}},
              { key: 'cedula', label: 'Cédula' }, { key: 'fullName', label: 'Nombre Completo'}, 'RC', { key: 'f_estampado', label: 'Fecha de Estampado', formatter: value => {return this.dateFormat(value)}}, { key: 'status', label: 'Estatus'}, 'tracking', 'email', 'phone', 'update', 'SendEmail'],
      totalPages:null,
      page: 1,
      rcOpts: [ {value: 0, text: 'Sin Registro Consular'}, { value: 2, text: 'Desactualizado'}, { value: 1, text: 'Actualizado'}],
      statusOpts: [ { value: 0, text: 'Sin procesar'},{ value: 1, text: 'Procesado'}]
    }
  },
  methods: {
    async pageClick(event){
      if(event.target.innerText == 'Prev'){
        this.page = this.page -1 }
        else if(event.target.innerText == 'Last'){
          this.page = this.totalPages }
        else if(event.target.innerText == 'First'){
          this.page = 1 }
        else if(event.target.innerText == 'Next'){
          this.page = this.page + 1 }
        else {
          this.page = event.target.innerText
        }
      const response = await fetch(`/api/getreglist/${this.page}`);
      const dataRes = await response.json();
      this.items = dataRes[1];
      this.totalPages = dataRes[0];
    },
    dateFormat(date){
      const value = new Date(date);
      return value.toLocaleDateString("en-GB", { year: "numeric", day: "2-digit", month: "2-digit"});
    },
    rowColor(item){
      if(item.status == 1){ return 'table-success' }
    },
    async updatereg(serial){
      const response = await fetch('/api/updatereg/', 
                      { method: 'PUT', mode: 'cors', headers: {'Content-Type': 'application/json'}, 
                      body: JSON.stringify([this.$store.state.id,serial,this.form])});
      const dataRes = await response.json();
      alert(dataRes[0]);
      this.refreshlist(this.page);
    },
    showEdit({serial, RC, status, f_estampado, tracking, phone}){
      this.form.RC = RC;
      this.form.status = status;
      this.form.tracking = tracking;
      this.form.f_estampado = f_estampado;
      this.form.phone = phone;
      this.$bvModal.show(serial);
    },
    async refreshlist(page){
      const response = await fetch(`/api/getreglist/${page}`);
      const dataRes = await response.json();
      this.totalPages = dataRes[0];
      this.items = dataRes[1];
    },
    async searchquery(){
      const body = [1,this.search];
      fetch('/api/searchquery/',{ method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body: JSON.stringify(body)})
      .then(res => res.json()).then((data) => { this.items = data });
      // const dataRes = await response.json();
      // this.items = response;
      this.page = 1;
      // this.clearSearch();
    },
    clearSearch(){
      this.search = {
        lote: undefined,
        fullName: undefined,
        cedula: undefined,
        phone: undefined,
        f_estampado: undefined,
        f_solicitud: undefined,
        serial: undefined,
        status: undefined,
        RC: undefined
      }
    },
    sendemail(data){
      fetch('/api/sendemail',{ method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json'}, body: JSON.stringify([this.$store.state.id,3,data])});
      // alert('Email queued!')
    }
  },
    async mounted() {
      this.refreshlist(1);
    }
}

// si no tiene telefono no mostrar el whatsapp
// si aun no tiene tracking no mostrar el logo del Post
</script>